<template>
  <b-card no-body>
    <b-card-title class="px-2 pt-1">รายชื่อผู้โชคดี</b-card-title>
    <b-table v-if="items" small striped hover responsive show-empty class="position-relative items-center" :fields="fields"
      :items="items.data">
      <template #cell(index)="data">
        {{ items.perPage * (items.currentPage - 1) + (data.index + 1) }}
      </template>
      <template #empty="scope">
        <div class="p-2 text-center">
          <img
            src="/box.png"
            alt="empty"
            height="40px"
          > {{ scope.emptyFiltered ? 'No matching records found' : 'ไม่พบข้อมูล' }}
        </div>
      </template>
      <template #cell(date)="row">
        {{ row.item.date }}
      </template>
      <template #cell(username)="row">
        {{ row.item.username }}
      </template>
      <template #cell(reward)="row">
        {{ row.item.reward.toLocaleString() }} บาท
      </template>
      <template #cell(timestamp)="row">
        {{ row.item.timestamp | convertDate }}
      </template>
    </b-table>
    <b-card-body v-if="items" class="d-flex justify-content-between flex-wrap pt-0">
      <b-pagination v-model="items.page" :total-rows="items.total" :per-page="items.perPage" first-number last-number
        prev-class="prev-item" next-class="next-item" class="mb-0">
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCardTitle, BIcon, BDropdownItem, BDropdown, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody
} from 'bootstrap-vue'
import moment from 'moment-timezone'

export default {
  components: {
    BCardTitle, BIcon, BDropdownItem, BDropdown, BBadge, BOverlay, BIconController, BInputGroupPrepend, BModal, BCard, BTable, BAvatar, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton, BCardBody,
  },
  props: ['items'],
  emits: [],
  filters: {
    convertDate(date) {
      return moment(date).tz('Asia/Bangkok').format('DD/MM/YYYY HH:ss')
    },
  },
  data: () => ({
    fields: [
      { key: 'date', label: 'วันที่ทายผล' },
      { key: 'username', label: 'ยูเซอร์เนม' },
      { key: 'reward', label: 'รางวัล' },
      { key: 'timestamp', label: 'เวลารับรางวัล' },
    ],
  })
}
</script>